<template>
  <header class="header">
    <div class="header__logo">
      <img
        style="margin-right: 14px; width: 100%;"
        src="../../assets/images/DispaLogoGray2024.png"
        alt="Логотип"
      />
    </div>
    <h1>
      Отчет водителю<span v-if="user.autoParkName" class="header__autopark">
        в автопарке {{ user.autoParkName }}
      </span>
    </h1>
    <div class="header__dropdown">
      <div class="header__dropdown--block">
        <div>
          <p style="margin-bottom: 5px">
            {{ user.lastName }} {{ user.firstName }}
          </p>
          <p @click="logoutHandler" class="header__dropdown--logout">
            Выход
            <img src="../../assets/images/logout.svg" alt="Выйти" />
          </p>
        </div>
        <div v-if="user.photo" class="header__dropdown--img">
          <div class="header__dropdown--user">
            <img
              :src="`https://d.tkglp.ru/uploads/drivers/${user.id}/image/${user.photo}`"
              alt="Профиль"
            />
          </div>
        </div>
        <div v-else class="header__dropdown--img">
          <img src="../../assets/images/user.svg" alt="Пользователь" />
          <img
            class="header__dropdown--profile"
            src="../../assets/images/profile.svg"
            alt="Профиль"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "TheHeader",
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapActions(["GET_USER_DATA", "REMOVE_TOKENS"]),

    logoutHandler() {
      this.REMOVE_TOKENS();
      this.$router.push("/auth");
    }
  },
  mounted() {
    this.GET_USER_DATA();
  }
};
</script>

<style lang="scss">
.header {
  background: $color-header;
  border-bottom: 1px solid $color-grey-light;
  height: 69px;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;

  h1 {
    @include flex;
    @include font-family;
    color: $color-basic;
    font-size: 32px;
    height: 100%;
    line-height: 102%;
    width: 100%;
  }

  &__logo {
    @include flex;
    left: $main-margin;
    position: absolute;
    height: 100%;
    width: 240px;
  }

  &__dropdown {
    color: $color-basic;
    height: 52px;
    position: absolute;
    right: $main-margin;
    top: 9px;
    width: 230px;

    &--block {
      @include flex;
      @include flex-content-end;
      height: inherit;
      font-size: 16px;
      text-align: right;
      width: inherit;
    }

    &--logout {
      @include flex;
      @include flex-content-end;
      cursor: pointer;
      font-size: 12px;
      line-height: 14px;

      img {
        margin-left: 3px;
      }
    }

    &--img {
      height: 47px;
      margin: 0 5px 0 18px;
      position: relative;
      width: 47px;
    }

    &--profile {
      height: 41px;
      position: absolute;
      right: 1px;
      top: 3px;
    }

    &--user {
      @include flex;
      border: 1px solid $color-grey-light;
      border-radius: 50%;
      height: 47px;
      overflow: hidden;
      position: absolute;
      width: 47px;

      img {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__autopark {
    background: #4a95ec;
    color: $color-white;
    border-radius: 44px;
    display: inline-block;
    font-size: 12px;
    margin-left: 10px;
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    #bigLogo,
    h1 {
      display: none;
    }

    .header__logo {
      width: 200px;
    }
  }
  @media (max-width: 540px) {
    #bigLogo,
    h1 {
      display: none;
    }

    .header__logo {
      width: 160px;
    }
  }
}
</style>
